import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, inject } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { AppService } from "./app.service";
import { ApiService } from "./services/api.service";
import { AuthZeroService } from "./services/auth-zero.service";
import { CartaoCreditoService } from "./services/cartao-credito.service";
import { CepService } from "./services/cep.service";
import { ClienteService } from "./services/cliente.service";
import { CriptografiaService } from "./services/criptografia.service";
import { CyberSourceService } from "./services/cybersource.service";
import { DebitoService } from "./services/debito.service";
import { DispositivoService } from "./services/dispositivo.service";
import { EnderecoService } from "./services/endereco.service";
import { EtapaService } from "./services/etapa.service";
import { FormularioService } from "./services/formulario.service";
import { GoogleAnalyticService } from "./services/google-analytic.service";
import { LocalStorageService } from "./services/local-storage.service";
import { LogService } from "./services/log.service";
import { PagamentoService } from "./services/pagamento.service";
import { PayloadService } from "./services/payload.service";
import { PedidoService } from "./services/pedido.service";
import { RecaptchaService } from "./services/recaptcha.service";
import { TokenService } from "./services/token.service";
import { UrlService } from "./services/url.service";
import { UtilService } from "./services/util.service";
import { VeiculoService } from "./services/veiculo.service";
import { AccountService } from "./services/account.service";

@Component({ template: "" })
export abstract class AppAbstract implements OnDestroy {
    protected _apiService = inject(ApiService);
    protected _accountService = inject(AccountService);
    protected _appService = inject(AppService);
    protected _authZeroService = inject(AuthZeroService);
    protected _carService = inject(VeiculoService);
    protected _cepService = inject(CepService);
    protected _clienteService = inject(ClienteService);
    protected _creditCardService = inject(CartaoCreditoService);
    protected _cyberSourceService = inject(CyberSourceService);
    protected _debitoService = inject(DebitoService);
    protected _deviceService = inject(DispositivoService);
    protected _encryptionService = inject(CriptografiaService);
    protected _formularioService = inject(FormularioService);
    protected _ga4Service = inject(GoogleAnalyticService);
    protected _enderecoService = inject(EnderecoService);
    protected _httpClient = inject(HttpClient);
    protected _localStorageService = inject(LocalStorageService);
    protected _logService = inject(LogService);
    protected _payloadService = inject(PayloadService);
    protected _pagamentoService = inject(PagamentoService);
    protected _pedidoService = inject(PedidoService);
    protected _recaptchaService = inject(RecaptchaService);
    protected _router = inject(Router);
    protected _etapaService = inject(EtapaService);
    protected _tokenService = inject(TokenService);
    protected _urlService = inject(UrlService);
    protected _utilService = inject(UtilService);
    protected _veiculoService = inject(VeiculoService);

    protected _formBuilder = new FormBuilder();

    protected _subSink = new SubSink();

    ngOnDestroy(): void {
        this._subSink.unsubscribe();
    }
}