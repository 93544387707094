import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UrlService } from './url.service';
import { gql } from 'apollo-angular'
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AccountService {


  constructor(private apollo: Apollo) {

  }

  private _apiService = inject(ApiService);
  private _urlService = inject(UrlService);

  getCodeOperations(): Observable<any> {

    const GET_CODE_GRAPHQL = gql`
  query {
    collectingAgentFilter(request: {
    companyIdentification: "CAIXA"
    agentIdentification: "104"
  }) {
    identification
    name
    bankingOperations {
      code
      description
    }
  }
  }
`

    return this.apollo.watchQuery({query: GET_CODE_GRAPHQL}).valueChanges;
  }

  validateAccount(payload: any): Observable<any> {
    return this._apiService.post(this._urlService.getValidateAccount(), payload);
  }
}
