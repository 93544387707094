import { Component, OnInit } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';
import { FormaPagamentoEnum } from 'src/app/enuns/forma-pagamento.enum';

@Component({
  selector: 'ftc-menu-ativar',
  templateUrl: './menu-ativar.component.html',
  standalone: true,
})
export class MenuAtivarComponent extends AppAbstract implements OnInit {
  FormaPagamentoEnum: typeof FormaPagamentoEnum = FormaPagamentoEnum;

  ngOnInit(): void {
    this.toggleCyberSourceScripts();
  }

  protected togglePagamento(value: FormaPagamentoEnum): void {
    this._pagamentoService.definirPagamento(value);
    this.toggleCyberSourceScripts();
  }

  public get pagamento(): string {
    return this._pagamentoService.formaPagamento;
  }

  private toggleCyberSourceScripts(): void {
    this._pagamentoService.formaPagamento === FormaPagamentoEnum.CARTAO_CREDITO
      ? this._cyberSourceService.adicionarCyberSourceScripts()
      : this._cyberSourceService.removerCyberSouceScripts();
  }
}
